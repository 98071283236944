<template>
  <v-row class="match-height">
    <v-col cols="12">
      <demo-form-layout-tabs></demo-form-layout-tabs>
    </v-col>
  </v-row>
</template>

<script>
import DemoFormLayoutTabs from './tabla-solicitud.vue'

export default {
  components: {
    DemoFormLayoutTabs,
  },
  setup() {
    return {}
  },
}
</script>
