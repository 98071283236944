import axios from 'axios'
import { headers } from './header'

export const SolicitudService = {
  getLista,
  postCursar,
  postDesistir,
  paginacion,
}

const URL_API_ADMIN = `${process.env.VUE_APP_API_HOST_ADMIN}/api/v1`
const URL_API_VENTA = `${process.env.VUE_APP_API_HOST_VENTA}/api/v1`

async function getLista(data) {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API_VENTA}/anulacionPoliza/${data}`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function postCursar(data) {
  const requestOptions = {
    method: 'POST',
    url: `${URL_API_VENTA}/anulacionPoliza/cursar`,
    headers: headers(),
    withCredentials: true,
    data,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function postDesistir(data) {
  const requestOptions = {
    method: 'POST',
    url: `${URL_API_VENTA}/anulacionPoliza/desistir`,
    headers: headers(),
    withCredentials: true,
    data,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}
async function paginacion(currentPage, catidadRegistros, codigoAs400) {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API_VENTA}/anulacionPoliza/${codigoAs400}?paginado=true&page=${catidadRegistros}&perPage=${currentPage}`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}
