<template>
  <app-card-code
    :code="codeSnippets.formLayoutTabs"
    name="cursoPoliza"
  >
    <h3 class="ml-5">
      Curso solicitud
    </h3>

    <v-card flat>
      <v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            class="d-flex justify-end"
            color="primary"
            x-small
            text
            :loading="loading"
            :disabled="loading"
            @click="actualizar_tabla"
          >
            Actualizar tabla
            <v-icon class="ml-2">
              {{ icons.mdiAccountConvert }}
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <label class="d-inline-flex align-items-center">
            <v-text-field
              v-model="filter"
              type="search"
              placeholder="Buscar..."
            ></v-text-field>
          </label>
        </v-card-actions>
        <v-data-table
          :headers="headers"
          :items="solicitudes"
          class="elevation-1 mt-0"
          color="indigo darken-5"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          :loading="loading"
          :search="filter"
        >
          <!--@page-count="pageCount = $event"-->
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialogCurse"
                max-width="500px"
              >
                <v-card name="modalCursarSolicitud">
                  <v-card-title class="text-h5">
                    Cursar solicitud
                  </v-card-title>

                  <v-card-text>
                    {{ textCursar }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      name="botonSi"
                      color="success"
                      :loading="loadingTrue"
                      :disabled="loadingTrue"
                      @click="aceptarcurso"
                    >
                      Si
                    </v-btn>
                    <v-btn
                      name="botonNo"
                      color="error"
                      @click="dialogCurse = false"
                    >
                      No
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialogDesistir"
                max-width="500px"
              >
                <v-card name="modalCancelarSolicitud">
                  <v-card-title class="text-h5">
                    Cancelar solicitud
                  </v-card-title>

                  <v-card-text>
                    {{ textDesistir }}
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      name="botonSi"
                      color="success"
                      @click="aceptarDesistir"
                    >
                      Si
                    </v-btn>
                    <v-btn
                      name="botonNo"
                      color="error"
                      @click="dialogDesistir = false"
                    >
                      No
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template
            v-slot:item.acciones="{ item }"
            class="textos"
          >
            <v-row class="mb-0">
              <v-col
                md="6"
                cols="6"
              >
                <v-btn
                  name="botonPermitirSolicitud"
                  color="primary"
                  x-small
                  text
                  title="Curse de solicitud"

                  @click="curseSolicitud(item)"
                >
                  <v-icon :size="20">
                    {{ icons.mdiThumbUp }}
                  </v-icon>
                </v-btn>
              </v-col>
              <!-- boton dos ********************************* -->
              <v-col
                md="6"
                cols="6"
              >
                <v-btn
                  name="botonNegarSolicitud"
                  color="primary"
                  x-small
                  text
                  title="Desistir en la solicitud"
                  @click="desistirSolicitud(item)"
                >
                  <v-icon :size="20">
                    {{ icons.mdiThumbDown }}
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:no-data>
            No existen datos para establa
          </template>
        </v-data-table>
        <div v-show="!loading">
          <div class="text-center d-flex pt-2 justify-end">
            <v-row
              class="mt-2 ml-5"
              align="center"
              justify="center"
            >
              <span class="grey--text">Paginación</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    text
                    color="primary"
                    class="ml-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ itemsPerPage }}
                    <v-icon>{{ icons.mdiChevronDown }}</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              {{ page }} / {{ numberOfPage }}

              <v-spacer></v-spacer>
            </v-row>
            <v-pagination
              v-model="page"
              :length="numberOfPage"
              :total-visible="6"
              circle
              @click.native="paginar(page)"
            >
            </v-pagination>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="isFormSubmittedSnackbarVisible2"
      right
      top
      :color="colorSnackbar"
    >
      {{ textSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="isFormSubmittedSnackbarVisible2 = false"
        >
          <v-icon>{{ icons.mdiCloseThick }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </app-card-code>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiThumbUp, mdiThumbDown, mdiAccountConvert, mdiChevronDown } from '@mdi/js'
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import { formLayoutTabs, dialogFullScreen } from './code'
import { SolicitudService } from '../../../service/solicitud.service'

export default {
  components: {
    AppCardCode,
  },
  setup() {
    const loading = ref(false)
    const loadingTrue = ref(false)
    const solicitudes = ref([])
    const dialogCurse = ref(false)
    const dialogDesistir = ref(false)
    const textCursar = ref('')
    const textDesistir = ref('')

    const isFormSubmittedSnackbarVisible2 = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')
    const cursoSolicitud = ref({})
    const cancelarSolicitud = ref({})
    const filter = ref('')

    return {
      codeSnippets: {
        formLayoutTabs,
        dialogFullScreen,
      },
      solicitudes,
      headers: [
        { text: 'Número solicitud', value: 'numeroSolicitud' },
        { text: 'Fecha solicitud', value: 'fechaSolicitud' },
        { text: 'Rut Asegurado', value: 'rutAsegurado' },
        { text: 'Nombre Asegurado', value: 'nombreAsegurado' },
        { text: 'Producto', value: 'producto' },
        { text: 'Motivo', value: 'motivo' },
        { text: 'Acciones', value: 'acciones' },
      ],
      icons: {
        mdiClose,
        mdiThumbUp,
        mdiThumbDown,
        mdiAccountConvert,
        mdiChevronDown,
      },
      loading,
      loadingTrue,
      dialogCurse,
      dialogDesistir,
      textCursar,
      textDesistir,
      isFormSubmittedSnackbarVisible2,
      colorSnackbar,
      textSnackbar,
      cursoSolicitud,
      cancelarSolicitud,
      filter,
      page: 1,
      numberOfPage: 0,
      itemsPerPage: 15,
      itemsPerPageArray: [5, 10, 15, 20, 25],
      perPage: 0,
    }
  },
  created() {
    this.listaSolicitudes()
  },
  methods: {
    validRut(value) {
      // const valor = value.replace(/[^A-Z0-9]/gi, '')
      let valor = value.replace('-', '')
      valor = valor.replace('-', '')
      const cuerpo = valor.slice(0, -1)
      let dv = valor.slice(-1).toUpperCase()

      value = `${cuerpo}-${dv}`

      let suma = 0
      let multiplo = 2
      for (let i = 1; i <= cuerpo.length; i++) {
        const index = multiplo * valor.charAt(cuerpo.length - i)
        suma += index
        if (multiplo < 7) {
          multiplo += 1
        } else {
          multiplo = 2
        }
      }
      const dvEsperado = 11 - (suma % 11)

      // Casos Especiales (0 y K)
      dv = dv === 'K' ? 10 : dv
      dv = dv === 0 ? 11 : dv

      // Validar que el Cuerpo coincide con su Dígito Verificador
      if (dvEsperado != dv) {
        this.respuestaRut = false
        this.productos = null
        this.stipeActive = false
        this.refrechData()

        return false
      }

      return value
    },
    formatFecha(valor) {
      const dato = valor.split('-')

      return typeof dato === typeof [] ? `${dato[2]}-${dato[1]}-${dato[0]}` : 'Sin información'
    },
    paginar(currentPage) {
      this.page = currentPage
      this.listaSolicitudes()
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
      this.paginar(this.page)
    },

    async listaSolicitudes() {
      this.loading = true
      this.perPage = 0 + 1
      const dataUser = JSON.parse(localStorage.getItem('userData')).codigo
      this.solicitudes = []
      await SolicitudService.paginacion(this.itemsPerPage, this.page, dataUser).then(resp => {
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          this.numberOfPage = Math.ceil(resp.data.pagination.pages)
          this.solicitudes = resp.data.data.map(e => ({
            numeroSolicitud: e.numeroSolicitud,
            fechaSolicitud: this.formatFecha(e.fechaSolicitud),
            rutAsegurado: this.validRut(e.rutAsegurado),
            nombreAsegurado: e.nombreAsegurado,
            producto: e.producto,
            motivo: e.motivo,
          }))
          this.loading = false
        } else {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'error'
          this.loading = false
        }
      })
    },
    async actualizar_tabla() {
      this.page = 1
      this.listaSolicitudes()
    },
    curseSolicitud(item) {
      this.dialogCurse = true
      this.textCursar = `¿Desea cursar solicitud de cancelación N° ${item.numeroSolicitud}
      segun motivo ${item.motivo} del producto ${item.producto} contratado por ${item.nombreAsegurado}?`
      this.cursoSolicitud = item
    },
    async aceptarcurso() {
      this.loadingTrue = true
      const fecha = new Date()
      const dataUser = JSON.parse(localStorage.getItem('userData')).codigo
      const data = {
        numeroSolicitud: this.cursoSolicitud.numeroSolicitud,
        usuarioCodigo: dataUser,
        fechaHora: `${fecha.toISOString().split('T')[0]} ${fecha.toLocaleTimeString('it-IT')}`,
      }
      await SolicitudService.postCursar(data).then(resp => {
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          this.loadingTrue = false
          this.dialogCurse = false
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = `Solicitud ${resp.data.data.estado}`
          this.colorSnackbar = 'success'
          this.actualizar_tabla()
        } else {
          this.dialogCurse = false
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'error'
        }
      })
    },
    desistirSolicitud(item) {
      this.dialogDesistir = true
      this.textDesistir = `¿Desea cancelar solicitud de cancelación N° ${item.numeroSolicitud}
      segun motivo ${item.motivo} del producto ${item.producto} contratado por ${item.nombreAsegurado}?`
      this.cancelarSolicitud = item
    },
    async aceptarDesistir() {
      const fecha = new Date()
      const dataUser = JSON.parse(localStorage.getItem('userData')).codigo
      const data = {
        numeroSolicitud: this.cancelarSolicitud.numeroSolicitud,
        usuarioCodigo: dataUser,
        fechaHora: `${fecha.toISOString().split('T')[0]} ${fecha.toLocaleTimeString('it-IT')}`,
      }
      await SolicitudService.postDesistir(data).then(resp => {
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          this.dialogDesistir = false
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = `Solicitud ${resp.data.data.estado}`
          this.colorSnackbar = 'success'
          this.actualizar_tabla()
        } else {
          this.dialogDesistir = false
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'error'
        }
      })
    },
  },
}
</script>
<style scoped>
.v-btn--fab.v-size--x-small {
  height: 20px !important;
  width: 20px !important;
}
.accion {
  margin-bottom: 1px;
}
a {
  text-decoration: none;
}
.textos {
  padding: 5px;
  font-size: 13px;
}
.header-inf-basica {
  background: linear-gradient(98deg, #67afed, var(--v-primary-base) 94%);
  color: white !important;
  font-weight: 300 !important;
  text-align: center;
  border-radius: 10px;
}
.header-inf-basica-text {
  color: white !important;
  font-weight: 300 !important;
}
</style>
